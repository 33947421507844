
import { CurrentUser } from '@/types';
import Vue from '@/vueTyped';

export default Vue.extend({
    name: 'Profile',
    metaInfo: {
        title: 'Account',
    },
    components: {
    },
    data() {
        return {
        };
    },
    computed: {
      currentUser(): CurrentUser | null {
        return this.$store.state.account.currentUser;
      },
    },
});
