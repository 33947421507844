
import Vue from '@/vueTyped';
import rules from '@/validation-rules';
import ThirdPartyLogInButtons from './Account/ThirdPartyLogInButtons.vue';
import allowedOrigins from '../helper-pages/allowed-origins';

export default Vue.extend({
    components: {
        ThirdPartyLogInButtons,
    },
    metaInfo: {
        title: 'Log In',
    },
    data() {
        return {
            valid: true,
            email: this.$store.state.account.visitorEmail,
            password: '',
            message: '',
            loading: false,
            rules,
        };
    },
    methods: {
        setVisitorEmail() {
            this.$store.commit('SET_VISITOR_EMAIL', this.email);
        },
        async login(event: Event) {
            event.preventDefault();
            if (this.loading) {
                return;
            }
            this.loading = true;
            const result = await this.$store.dispatch('accountLogin', { email: this.email, password: this.password });
            this.message = result.error ? 'Username or password are incorrect.' : '';
            this.loading = false;
            if (!result.error) {
                const nextRoute = (this.$route.query.redirect as string) ?? '/';
                if (
                    nextRoute.includes('://')
                    && allowedOrigins.some(o => nextRoute.startsWith(o))
                ) {
                    location.assign(nextRoute);
                } else {
                    this.$router.push(nextRoute);
                }
            }
        },
    },
});
