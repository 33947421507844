
import Vue from '@/vueTyped';

export default Vue.extend({
    props: {
        password: {
            type: String,
            default: '',
        }
    },

    computed: {
        hasNumber(): boolean {
            return !!this.password.match(/[0-9]/);
        },

        hasLength(): boolean {
            return this.password.length > 7;
        },

        hasCap(): boolean {
            return !!this.password.match(/[A-Z]/);
        },
    },
});
