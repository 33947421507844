
import Vue from '@/vueTyped';
import { ANDROID_APP_LINK, IOS_APP_LINK } from '@/config';
import { trackAppLinkClick } from '@/tracking';

export default Vue.extend({
    props: {
        size: {
            type: String,
            default: '40px'
        },

        outlined: {
            type: Boolean,
            default: false,
        },

        stacked: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            androidLink: ANDROID_APP_LINK,
            iosLink: IOS_APP_LINK,
        };
    },

    methods: {
        handleClick(platform: string) {
            this.$emit('click-link', platform);
            trackAppLinkClick(platform);
        },
    },
});
