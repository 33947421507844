
import LoadingIndicator from '@/components/LoadingIndicator.vue';
import { UserGroup } from '@/types';
import Vue from '@/vueTyped';

declare namespace Intl {
  class ListFormat {
    constructor(locale: string);
    format: (items: unknown[]) => string;
  }
}

export default Vue.extend({
    components: {
        LoadingIndicator,
    },

    i18n: {
        messages: {
            en: {
                title: 'Group invitation: {name}',
                heading: 'You’ve received an invitation to join',
                from: 'from',
                join: 'Join now!',
                success: 'You’re now a member of {groupName}!',
                groupNotFound: 'Sorry, we couldn’t find that group. Please check your link and try again.',
                invalidToken: 'Sorry, that token is invalid. It may have expired, so please check with the person who sent it!'
            },

            es: {
                title: 'Invitación de grupo: {name}',
                heading: 'Has recibido una invitación para unirte a',
                from: 'de parte de',
                join: '¡Únase ahora!',
                success: '¡Ahora eres un miembro de {groupName}!',
                groupNotFound: 'Lo sentimos, no hemos podido encontrar ese grupo. Por favor, compruebe su enlace y vuelva a intentarlo.',
                invalidToken: 'Lo sentimos, ese token no es válido. Es posible que haya caducado, así que comprueba con la persona que lo envió.'
            },
        },
    },

    metaInfo(): any {
        return {
            title: this.$t('title', { name: this.userGroup?.name ?? '···'}),
        };
    },

    props: {
        userGroupId: { type: String, required: true },
        inviteToken: { type: String, required: true },
    },

    data() {
        return {
            loading: 0,
            userGroup: null as UserGroup | null,
            loadError: null as Error | null,
            confirming: 0,
            confirmError: null as Error | null,
        };
    },

    computed: {
        ownerNames(): string {
            const names = (this.userGroup?.owners ?? []).map(owner => {
                return owner.clientGroup?.name ?? `${owner.user?.firstName} ${owner.user?.lastName}`;
            });

            const formatter = new Intl.ListFormat(this.$i18n.locale);
            return formatter.format(names);
        },
    },

    watch: {
        userGroupId: {
            immediate: true,
            async handler(userGroupId) {
                try {
                    this.loading += 1;
                    const response = await this.$store.state.apiClient.get(`/user-groups/${userGroupId}`);
                    if (response.error) throw response.error;
                    this.userGroup = response.data.userGroup;
                } catch (error) {
                    this.loadError = error;
                } finally {
                    this.loading -= 1;
                }
            },
        },
    },

    methods: {
        async confirm() {
            try {
                this.confirming += 1;
                const response = await this.$store.state.apiClient.post(`/user-groups/${this.userGroupId}/invite-token/join`, { token: this.inviteToken });
                if (response.error) throw response.error;

                this.$store.dispatch('alertUser', {
                    type: 'info',
                    message: this.$t('success', { groupName: this.userGroup?.name ?? '···' }),
                });

                this.$router.replace('/');
            } catch (error) {
                this.confirmError = error;
            } finally {
                this.confirming -= 1;
            }
        },
    },
});

