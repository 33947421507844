
import AppDownloadLinks from '@/components/AppDownloadLinks.vue';
import Vue from '@/vueTyped';

export default Vue.extend({
    components: {
        AppDownloadLinks,
    },

    props: {
        nextRoute: { type: String, default: '' },
    }
});
