
import Vue from '@/vueTyped';
import TwoStepAddressSelector from '@/components/location/TwoStepAddressSelector.vue';
import { parseAddressComponentsFromMBobj } from '@/util.mapbox';
import { MBAddressObj, LngLat } from '@/types';
import { trackRegistrationLocation } from '@/tracking';


export default Vue.extend({
    name: 'SetupLocation',

    components: {
        TwoStepAddressSelector,
    },

    data() {
        return {
            location: null as MBAddressObj | null,
            coordinates: null as LngLat | null,
            saving: false,
            submissionError: null as string | null,
        };
    },

    methods: {
        async submit() {
            this.submissionError = null;

            (this.$refs.addressSelector as any).validate();

            if (this.location && this.coordinates) {
                this.saving = true;

                // `communicationPreferences` are set on the back end when the address is set for the first time.
                // `notifyMethod` is set in the first registration step, so make sure it's preserved.
                const notifyMethod = this.$store.state.account.currentUser?.communicationPreferences.notifyMethod;

                const save = await this.$store.dispatch('updateUserDetails', {
                    addressComponents: parseAddressComponentsFromMBobj(this.location),
                    lng: this.coordinates[0],
                    lat: this.coordinates[1],
                    successMessage: false
                });

                const secondSave = await this.$store.dispatch('updateUserDetails', {
                    communicationPreferences: {
                        ...save.data.users[0].communicationPreferences,
                        notifyMethod,
                    },
                    successMessage: false
                });

                trackRegistrationLocation(secondSave.data.users[0]);

                this.saving = false;

                if (save.error) {
                    this.submissionError = this.$t('account.locationFailed') as string;
                }
            }
        },
    },
});
