
import Vue from '@/vueTyped';
import rules from '@/validation-rules';

export default Vue.extend({
    name: 'AccountForgotPassword',
    metaInfo: {
        title: 'Forgot Password',
    },
    data() {
        return {
            email: this.$store.state.account.visitorEmail,
            // email: (this.$store.state.account as AccountState).visitorEmail,
            rules,
            success: false,
        };
    },
    methods: {
        async forgotpassword(event: Event) {
            event.preventDefault();
            const result = await this.$store.dispatch('accountForgotPassword', this.email);
            this.success = !result.error;
        },
    },
});
