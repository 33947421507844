
import { CurrentUser } from '@/types';
import Vue from '@/vueTyped';
import debounce from 'lodash/debounce';


export default Vue.extend({
    name: 'Comments',
    components: {
    },
    data() {
        return {
            debouncedSaveUserData: () => {},
            userData: {
                communicationPreferences: {},
                avatar: {},
                addressComponents: {},
            } as CurrentUser,
        };
    },
    computed: {
        currentUser() {
            return this.$store.state.account.currentUser;
        },
    },
    watch: {
        currentUser(val: CurrentUser) {
            if (val) {
                this.userData = val;
            }
        },
    },
    created() {
        if (this.currentUser) {
            this.userData = this.currentUser;
        }
        this.debouncedSaveUserData = debounce(this.saveUserData, 500);
    },
    methods: {
        saveUserData() {
            this.$store.dispatch('updateUserDetails', this.userData);
        },
    },
});
