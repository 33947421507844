
import Vue from '@/vueTyped';
import { CurrentUser } from '@/types';
import ProgressBar from '@/components/ProgressBar.vue';
import MarkdownOutput from '@/components/MarkdownOutput.vue';

export default Vue.extend({
    i18n: {
        messages: {
            en: {
                headline: 'Delete this account',
                pleaseDont: 'You’re about to delete your account! This is irreversible.\nIf you’d like to get in touch with us instead, you can reach us at community@iseechange.org.',
                deletePostsToo: 'Also delete {n} sighting | Also delete {n} sightings',
                areYouSure: 'Are you absolutely sure you want to delete this account? You cannot undo this.',
                action: 'Yes, delete this account forever'
            },

            es: {
                headline: 'Eliminar esta cuenta',
                pleaseDont: 'Estás a punto de eliminar tu cuenta. Esto es irreversible.\nSi quieres ponerte en contacto con nosotros, puedes hacerlo en community@iseechange.org.',
                deletePostsToo: 'También borrar {n} avistamiento | También borrar {n} avistamientos',
                areYouSure: '¿Estás absolutamente seguro de que quieres eliminar esta cuenta? No puedes deshacerlo.',
                action: 'Sí, borra esta cuenta para siempre'
            },
        },
    },

    components: {
        ProgressBar,
        MarkdownOutput,
    },

    data() {
        return {
            postCount: 0,
            deletePosts: false,
            inProgress: 0,
        };
    },

    computed: {
        allowDeletingPosts(): boolean {
            return Boolean(this.$route.query.posts);
        },

        currentUser(): CurrentUser | null {
            return this.$store.state.account.currentUser;
        },

        requestsRequired(): number {
            return 1 + (this.deletePosts ? this.postCount : 0);
        },
    },

    watch: {
        'currentUser.id': {
            immediate: true,
            handler(currentUserId) {
                if (currentUserId) {
                    this.fetchPostCount();
                } else {
                    this.postCount = 0;
                }
            },
        }
    },

    methods: {
        async fetchPostCount() {
            if (this.currentUser) {
                const { data } = await this.$store.state.apiClient.get(`/posts?user=${this.currentUser.id}&limit=1`);
                this.postCount = data.meta.total;
            } else {
                this.postCount = 0;
            }
        },

        async kickOffDeletion() {
            const confirmed = confirm(String(this.$t('areYouSure')));

            if (confirmed) {
                this.inProgress += 1;
                if (this.deletePosts) this.inProgress += this.postCount;
                await this.$store.dispatch('deleteCurrentUserAccount', {
                    andPosts: this.allowDeletingPosts && this.deletePosts,
                    onProgress: () => this.inProgress -= 1,
                });
                this.inProgress -= 1;
                this.$router.push({ name: 'home' });
            }
        },
    },
});
