
import Vue from '@/vueTyped';
import rules from '@/validation-rules';
import debounce from 'lodash/debounce';
import PasswordRules from '@/components/PasswordRules.vue';

export default Vue.extend({
    name: 'AccountCreateNewPassword',
    metaInfo: {
        title: 'Create New Password',
    },
    components: {
        PasswordRules,
    },
    data() {
        return {
            email: '',
            token: '',
            debouncedValidateMatched: () => {},
            success: false,
            failed: false,
            password: '',
            matchedPassword: '',
            rules,
            matchedPasswordErrors: '',
        };
    },
    computed: {
        passwords(): string[] {
            return [this.password, this.matchedPassword];
        },
        malformed(): boolean {
            return !this.email || !this.token;
        },
        disableSubmit(): boolean {
            return this.malformed || this.password === '' || this.matchedPassword === '' || this.matchedPasswordErrors !== '';
        },
    },
    watch: {
        passwords(val) {
            if (val[0] && val[1]) {
                this.debouncedValidateMatched();
            }
        },
    },
    created() {
        this.debouncedValidateMatched = debounce(this.validateMatched, 500);
        const email = this.$route.query.email;
        if (email && typeof email === 'string') {
            this.email = email;
            this.$store.commit('SET_VISITOR_EMAIL', this.email);
        }
        const token = this.$route.query.token;
        if (token && typeof token === 'string') {
            this.token = token;
        }

    },
    methods: {
        async createNewPassword(event: Event) {
            event.preventDefault();
            const result = await this.$store.dispatch('accountResetPassword', {
                password: this.password,
                token: this.token,
                email: this.email
            });
            this.success = !result.error;
            this.failed = !!result.error;
        },
        validateMatched() {
            if (this.password === this.matchedPassword) {
                this.matchedPasswordErrors = '';
            } else {
                this.matchedPasswordErrors = 'Passwords must be the same';
            }
        },
    },
});
