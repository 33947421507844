
import { defineComponent } from 'vue';

export default defineComponent({
    i18n: {
        messages: {
            en: {
                heading: 'Short on time?',
                body: 'Use the buttons below to create a quick report without signing-up.',
                ctaHeat: 'Extreme heat',
                ctaFlooding: 'Flooding',
            },

            es: {
                heading: '¿Corto tiempo?',
                body: 'Use los botones a continuación para crear un informe rápido sin registrarse.',
                ctaHeat: 'Calor extremo',
                ctaFlooding: 'Inundación',
            },
        },
    },
});
