
import LoadingIndicator from '@/components/LoadingIndicator.vue';
import RegistrationForm from '@/components/RegistrationForm.vue';
import TrackerCallout from '@/components/TrackerCallout.vue';
import { INSIGHTS_ORIGIN } from '@/config';
import SetupLocation from '@/pages/Registration/SetupLocation.vue';
import SetupProfile from '@/pages/Registration/SetupProfile.vue';
import { trackSignUpPageView } from '@/tracking';
import { CurrentUser } from '@/types';
import Vue from '@/vueTyped';
import allowedOrigins from '../../helper-pages/allowed-origins';
import { getOauthCode } from '../OAuth';
import ShowApps from './ShowApps.vue';

type ClientGroupInvite = {
    id: string;
    domains: string[];
    expires_at: Date | null;
    client_group: {
        id: string;
        name: string;
    };
};

const CLIENT_GROUP_INVITE_TOKEN_PARAM = 'client-invite';

export default Vue.extend({
    i18n: {
        messages: {
            en: {
                useClientEmail: 'Use your {name} email addess',
                contactSupport: 'Reach out to {supportEmailLink} with any issues',
                clientGroupInviteExpired: 'Sorry, this invitation has expired! Contact someone on your team for a new one, or reach out to {supportEmailLink} for help.',
                clientGroupInviteError: 'There was an error looking up this client group invitation. Contact someone on your team for a new one, or reach out to {supportEmailLink} for help.',
            },

            es: {
                useClientEmail: 'Use su {name} correo electrónico',
                contactSupport: 'Comuníquese con {supportEmailLink} con cualquier problema',
                clientGroupInviteExpired: '¡Lo siento, esta invitación ha expirado! Póngase en contacto con alguien de su equipo para uno nuevo, o comuníquese con {supportEmailLink} para obtener ayuda.',
                clientGroupInviteError: 'Hubo un error para buscar esta invitación de grupo de clientes, contacte a alguien en su equipo para una nueva, o comunicarse con {supportEmailLink} para obtener ayuda.',
            },
        },
    },

    metaInfo: {
        title: 'Sign Up',
    },

    components: {
        LoadingIndicator,
        RegistrationForm,
        SetupLocation,
        SetupProfile,
        ShowApps,
        TrackerCallout,
    },

    data() {
        return {
            clientGroupInviteJwt: sessionStorage.getItem('sessionClientGroupInviteJwt'),
            clientGroupInvite: null as ClientGroupInvite | null,
            fetchingClientGroupInvite: 0,
            clientGroupInviteError: null as Error | null,
            oauthCode: null as string | null,
        };
    },

    computed: {
        currentUser(): null | CurrentUser {
            return this.$store.state.account.currentUser;
        },

        showAppsNext() {
            return this.$route.query.show === 'apps';
        },

        clientGroupInviteExpired() {
            return this.clientGroupInvite?.expires_at && this.clientGroupInvite?.expires_at <= new Date();
        },

        nextRoute() {
            let redirectUrl = this.$route.query.redirect as string | undefined;
            if (!redirectUrl && this.clientGroupInvite) {
                const insightsUrl = new URL('/', INSIGHTS_ORIGIN);
                if (this.oauthCode) insightsUrl.searchParams.set('code', this.oauthCode);
                return insightsUrl.href;
            }
            if (redirectUrl?.includes('://')) {
                if (
                    this.showAppsNext
                    || !allowedOrigins.some(o => redirectUrl?.startsWith(o))
                ) {
                    redirectUrl = undefined;
                }
            }
            return redirectUrl ?? this.$router.resolve({ name: 'actions', query: { welcome: 'welcome' } }).href;
        },
    },

    watch: {
        clientGroupInviteJwt: {
            immediate: true,
            handler: 'fetchClientGroupInvite',
        },

        async currentUser(currentUser: CurrentUser | null, previousValue: CurrentUser | null) {
            if (currentUser && !previousValue) {
                this.oauthCode = await getOauthCode(new URLSearchParams([['client_id', 'isc_client_invite']]));
            } else if (!currentUser) {
                this.oauthCode = null;
            }
        },
    },

    mounted() {
        trackSignUpPageView();
        this.checkForClientGroupInviteJwt();
    },

    methods: {
        checkForClientGroupInviteJwt() {
            const paramValue = this.$route.query[CLIENT_GROUP_INVITE_TOKEN_PARAM];
            if (paramValue) {
                this.clientGroupInviteJwt = `${paramValue}`;
                this.$router.replace({
                    query: {
                        ...this.$route.query,
                        [CLIENT_GROUP_INVITE_TOKEN_PARAM]: undefined,
                    },
                });
            }
        },

        async fetchClientGroupInvite(jwt: typeof this.clientGroupInviteJwt) {
            let forgetJwt = false;

            if (jwt) {
                try {
                    this.fetchingClientGroupInvite += 1;
                    sessionStorage.setItem('sessionClientGroupInviteJwt', jwt);

                    const { token } = JSON.parse(atob(jwt.split('.')[1].replace(/-/g, '+').replace(/_/g, '/')));
                    const { data: response } = await this.$store.state.apiClient.get(`/../v3/client-group-registration-tokens/${token}`);

                    this.clientGroupInvite = {
                        ...response,
                        expires_at: response.expires_at === null ? null : new Date(response.expires_at),
                    } as ClientGroupInvite;

                    if (this.clientGroupInviteExpired) {
                        forgetJwt = true;
                    }
                } catch (error) {
                    this.clientGroupInviteError = error;
                    forgetJwt = true;
                } finally {
                    this.fetchingClientGroupInvite -= 1;
                }
            } else {
                this.clientGroupInvite = null;
                forgetJwt = true;
            }

            if (forgetJwt) {
                sessionStorage.removeItem('sessionClientGroupInviteJwt');
            }
        },

        async focusNextStep() {
            await this.$nextTick();
            setTimeout(() => {
                if (this.$refs.nextStepHolder instanceof HTMLElement) {
                    const firstInput = this.$refs.nextStepHolder.querySelector('button, input:not([type="file"]), textarea');
                    if (firstInput instanceof HTMLElement) {
                        firstInput.focus();
                    }
                }
            }, 50);
        },
    },
});
