
import { CurrentUser, MBAddressObj, LngLat } from '@/types';
import Vue from '@/vueTyped';
import { reverseGeocode, parseAddressComponentsFromMBobj } from '@/util.mapbox';
import TwoStepAddressSelector from '@/components/location/TwoStepAddressSelector.vue';
import UserAvatar from '@/components/UserAvatar.vue';
import ActionFooter from '@/components/ActionFooter.vue';
import { ValidationProvider } from 'vee-validate/dist/vee-validate.full.esm';
import { trackProfileUpdate } from '@/tracking';
import BaseFieldset from '@/components/BaseFieldset.vue';

export default Vue.extend({
    name: 'ProfileSettings',
    components: {
        ValidationProvider,
        TwoStepAddressSelector,
        UserAvatar,
        ActionFooter,
        BaseFieldset,
    },
    data() {
        return {
            newPhoto: null,
            dataLoaded: false,
            userData: {
                firstName: '',
                lastName: '',
                description: '',
                location: null as MBAddressObj | null,
                coordinates: null as LngLat | null,
                preferredSystemOfMeasure: '',
            },
            loadingAvatar: false,
            loading: false,
        };
    },
    computed: {
        currentUser(): CurrentUser | null {
            return this.$store.state.account.currentUser;
        },
    },
    watch: {
        currentUser: {
            immediate: true,
            async handler(val: CurrentUser) {
                if (val) {
                    this.userData.firstName = val.firstName;
                    this.userData.lastName = val.lastName;
                    this.userData.description = val.description;
                    this.userData.coordinates = null;
                    this.userData.location = null;
                    this.userData.preferredSystemOfMeasure = val.userSettings?.preferredSystemOfMeasure ?? '',
                    this.dataLoaded = true;

                    if (val.lng && val.lat) {
                        this.userData.coordinates = [val.lng, val.lat];
                        const coordinatesLookup = await reverseGeocode(this.userData.coordinates, { limit: 1 });
                        this.userData.location = coordinatesLookup.features[0];
                    }
                }
            },
        },
    },
    methods: {
        async updateProfilePhoto(photo: { webLink: string, file: any }) {
            if (this.currentUser) {
                this.loadingAvatar = true;
                await this.$store.dispatch('updateAvatar', {
                    file: photo.file,
                    userId: this.currentUser.id,
                });
                this.loadingAvatar = false;
            }
        },
        async saveUserData() {
            if (!this.currentUser) { return; }

            (this.$refs.addressInput as any).validate();

            const payload = {} as any;

            if (this.userData.firstName !== this.currentUser.firstName) {
                payload.firstName = this.userData.firstName;
            }

            if (this.userData.lastName !== this.currentUser.lastName) {
                payload.lastName = this.userData.lastName;
            }

            if (this.userData.description !== this.currentUser.description) {
                payload.description = this.userData.description;
            }

            if (this.userData.coordinates) {
                if (this.userData.coordinates[0] !== this.currentUser.lng || this.userData.coordinates[1] !== this.currentUser.lat) {
                    payload.lng = this.userData.coordinates[0];
                    payload.lat = this.userData.coordinates[1];

                    if (this.userData.location /* This should always be true in this case. */) {
                        payload.addressComponents = parseAddressComponentsFromMBobj(this.userData.location);
                    }
                }

            }

            this.loading = true;

            // Save settings first, since they're used to set defaults when updating details.
            if (this.userData.preferredSystemOfMeasure !== this.currentUser.userSettings?.preferredSystemOfMeasure) {
                await this.$store.dispatch('updateSettings', {
                    preferredSystemOfMeasure: this.userData.preferredSystemOfMeasure,
                });
            }

            const { data } = await this.$store.dispatch('updateUserDetails', payload);
            trackProfileUpdate(data.users[0]);

            this.loading = false;
        },
    },
});
