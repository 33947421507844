
import Vue from '@/vueTyped';
import { Photo } from '@/types';
import { BASE_API_URL } from '@/config';

export default Vue.extend({
    name: 'AsyncImage',
    props: {
        image: {
            type: Object as () => Photo,
            default: () => ({} as Photo),
        },
        width: {
            type: Number,
            default: 0,
        },
        height: {
            type: Number,
            default: 0,
        },
        cropIconThreshold: {
            type: Number,
            default: Infinity,
        },
    },
    computed: {
        requestDimensions(): { width: number, height: number } {
            let width = this.width;
            let height = this.height;
            if (!width && !height) {
                // TODO: We could pick something out based on the viewport's and image's sizes and orientations.
                throw new Error('AsyncImage requires at least a width or a height');
            }
            if (!width) {
                width = this.image.width / (this.image.height / this.height);
            }
            if (!height) {
                height = this.image.height / (this.image.width / this.width);
            }
            return { width, height };
        },
        url(): string {
            if (!this.image.dirPath) {
                return '';
            }
            const { width, height } = this.requestDimensions;
            return `${BASE_API_URL}/${this.image.dirPath}/w_${width},h_${height},c_fill,a_exif/${this.image.id}.${this.image.format}`;
        },
        cropDirection(): null | 'horizontal' | 'vertical' {
            let imageAspectRatio = this.image.width / this.image.height;
            const displayedAspectRatio = this.width / this.height;
            const ratioDiff = imageAspectRatio - displayedAspectRatio;
            if (Math.abs(ratioDiff) > this.cropIconThreshold) {
                return ratioDiff > 0 ? 'horizontal' : 'vertical';
            } else {
                return null;
            }
        }
    },
});
