import { render, staticRenderFns } from "./SetupLocation.vue?vue&type=template&id=83affe76&scoped=true"
import script from "./SetupLocation.vue?vue&type=script&lang=ts"
export * from "./SetupLocation.vue?vue&type=script&lang=ts"
import style0 from "./SetupLocation.vue?vue&type=style&index=0&id=83affe76&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "83affe76",
  null
  
)

export default component.exports