
import { CurrentUser, User, Device } from '@/types';
import Vue from '@/vueTyped';
import AsyncImage from '@/components/AsyncImage.vue';

export default Vue.extend({
    name: 'ProfileSensors',
    components: {
        AsyncImage,
    },
    data() {
        return {
        };
    },
    computed: {
        currentUser(): CurrentUser | User | null {
            return this.$store.state.account.currentUser;
        },
        userDevices(): Device[] {
            return this.$store.state.account.userDevices;
        },
    },
    created() {
        this.$store.dispatch('fetchDevices');
    },

});
