
import Vue from '@/vueTyped';

export default Vue.extend({
    components: {},

    props: {
        value: {
            type: Number,
            required: true,
        },

        max: {
            type: Number,
            required: true,
        },
    },
});
